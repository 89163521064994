import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import Banner from '../../components/NewTheme/Ui/Banner'
import BigButton from '../../components/Ui/BigButton'
import getTicketURL from '../../utils/TicketURL'

const Billetterie = () => {
  const metaTitle = ''
  const metaDescrition = ''
  const metaKeywords = ''

  return (
    <Wolflayout mobileTitle={'Billetterie'}>
      <FestifHelmet title={metaTitle} description={metaDescrition} keywords={metaKeywords}>
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>
      <Banner title="Billetterie" />
      <div className="container billetterie pb-2">

          {/* <h1>Ouverture<br/>de la billetterie<br/>le 14 avril à midi</h1> */}

          {/* TICKETS BUTTON */}
          <div className="my-24 !text-center md:text-left !mx-0 !px-0">
            <div className="md:mx-[40%] md:w-[20%] mx-0 w-full">
                <p className='!px-0 !mx-0 pb-4'><BigButton className={'!px-0 !mx-0'} href={getTicketURL()} text="ACHETER DES BILLETS" largeText={true}/></p>
            </div>

            <h3>La billetterie est ouverte!</h3>
            {/* <h3>Prévente locale : 10 avril</h3> */}
            {/* <h3>Ouverture de la billetterie en ligne : 11 avril à midi</h3> */}
          </div>

          <h2 className='hidden'>Passeports et billets</h2>
          <div className="flex flex-wrap w-full">

            {/* PASSEPORT LA TOTALE */}
            <a href={getTicketURL()} className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-yellow-wolf2024 rounded-t-3xl px-1 py-6">
                  <h4 className="leading-none !text-purple-wolf2024">Passeport</h4>
                  <h3 className="leading-none mb-3 !text-purple-wolf2024">La<br/>Totale</h3>
                  <h5 className='!text-purple-wolf2024'>Complet</h5>
                  {/* <p className="uppercase !text-purple-wolf2024">Frais en sus</p> */}
                  <p className="uppercase !text-purple-wolf2024"><br/></p>
                </div>
                <div className="!text-purple-wolf2024 py-4 px-6">
                  <h6 className="uppercase">Accès</h6>
                  <p>-</p>
                  <p>À tous les spectacles de la programmation à l’exception des Expériences d'écoute et du Bus Festif Radio-Canada.</p>
                  <p>Tu auras besoin de billets spécifiques pour ces spectacles.</p>
                  <p>-</p>
                  <h6>Tu devras présenter ton Passeport La Totale afin de pouvoir entrer sur les sites et accéder aux navettes</h6>
                  <div className='hidden md:block'>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                  </div>
                </div>
              </div>
            </a>

            {/* PASSEPORT PLACE DESJARDINS */}
            <a href={getTicketURL()} className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-blue-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h4 className="leading-none !text-purple-wolf2024">Passeport</h4>
                  <h3 className="leading-none !text-purple-wolf2024 mb-3">Place<br/>Desjardins</h3>
                  <h5 className='!text-purple-wolf2024'>Complet</h5>
                  {/* <p className="uppercase !text-purple-wolf2024">Frais en sus</p> */}
                  <p className="uppercase !text-purple-wolf2024"><br/></p>
                </div>
                <div className="!text-purple-wolf2024 py-4 px-6">
                  <h6 className="uppercase">Accès</h6>
                  <p>-</p>
                  <p>À tous les spectacles de la Place Desjardins</p>
                  <p>-</p>
                  <h6 className="uppercase">23 rue Ambroise-Fafard</h6>
                  <p>Le site est situé en plein cœur du Centre-Ville. L’accès se fait à côté du Musée d’Art Contemporain. Ce site est en formule debout. Il est interdit d’apporter vos propres chaises (à moins d’une contrainte physique). </p>
                  <p>-</p>
                  <h6>Tu devras présenter ton Passeport Desjardins afin de pouvoir entrer sur le site.</h6>
                </div>
              </div>
            </a>

            {/* BILLETS UNITAIRES */}
            <a href={getTicketURL()} className="md:w-1/3 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-red-wolf2024 rounded-t-3xl px-1 py-6">
                  <h4 className="leading-none pb-4 !text-purple-wolf2024"></h4>
                  <h3 className="leading-none mb-3 !text-purple-wolf2024">Billets<br/>unitaires</h3>
                  <h5 className='!text-purple-wolf2024'>15$ à 40$</h5>
                  <p className="uppercase !text-purple-wolf2024">Frais en sus</p>
                </div>
                <div className="!text-purple-wolf2024 py-4 px-6">
                  <h6 className="uppercase">Accès</h6>
                  <p>-</p>
                  <p>Tous les spectacles payants sont disponibles en billets unitaires.</p>
                  <p><br/></p>
                  <p>Tu devras présenter ton billet électronique pour entrer sur le site de ton spectacle.</p>
                  <p>45% de la programmation est gratuite et ne nécessite pas de billet.</p>
                  <div className='hidden md:block'>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                    <p><br/></p>
                  </div>
                </div>
              </div>
            </a>

          </div>

          <div className="flex flex-wrap w-full mt-8">

            {/* ORANGE BISTRO */}
            <a href={'https://orangebistro.com/forfaits/'} target='_blank' rel="noreferrer" className="md:w-1/2 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-green-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3 !text-purple-wolf2024">Souper-spectacle à<br/> l’Orange Bistro</h3>
                  <h5 className='!text-purple-wolf2024'>85$ à 115$</h5>
                  <p className="uppercase !text-purple-wolf2024">Frais en sus</p>
                </div>
                <div className="!text-purple-wolf2024 py-4 px-6">
                  <h6>Réservation via le restaurant uniquement.</h6>
                  <p>-</p>
                  <p>Clique ici pour plus d’informations!</p>
                </div>
              </div>
            </a>

            {/* CAMPINGS */}
            <a href={getTicketURL()} className="md:w-1/2 w-full text-center px-4 md:my-0 my-2">
              <div className="bg-white rounded-3xl">
                <div className="bg-yellow-wolf2024 rounded-t-3xl px-1 py-6 text-white">
                  <h3 className="leading-none mb-3 !text-purple-wolf2024 py-2">Campings</h3>
                  <h5 className='!text-purple-wolf2024'>45$ à 220$</h5>
                  <p className="uppercase !text-purple-wolf2024">Frais en sus</p>
                </div>
                <div className="!text-purple-wolf2024 pt-4 pb-2 px-6">
                  <p>Prix par terrain et non par personne.</p>
                  <p>Pour toutes informations,<br/>rendez-vous sur la page <a className="!font-bold underline hover:no-underline" href="/infos/hebergements#info_content">hébergement</a></p>
                  <div className='hidden md:block'>
                    <p><br/></p>
                  </div>
                </div>
              </div>
            </a>

          </div>

          {/* PRIX PDF */}
          {/* <div className="px-4 my-4 pt-1">
            <p><BigButton href={getTicketURL()}
                          text="ACHETER DES BILLETS"/></p>
          </div> */}
          <div className="px-4">
            <p><BigButton href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZhWwHzjCgu4jztsG_prix-billets.pdf"
                          target='_blank'
                          text="CONSULTER TOUS LES PRIX"/></p>
          </div>
          <div className="px-4">
            <p><BigButton href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZhWwIDjCgu4jztsH_prix-camping.pdf"
                          target='_blank'
                          text="CONSULTER LES PRIX DES CAMPINGS"/></p>
          </div>

          <div className="md:!px-[30px] !px-[10px]">
          <h2 className="!text-left mb-2 mt-6">Informations générales</h2>
            <ul>
              <li>Les spectacles <b>gratuits</b> ne nécessitent pas de billet, arrive tôt pour t'assurer une place.</li>
              <li>Il y a des frais postaux de 5,30$ pour les envois de Passeports (maximum de 4 Passeports par enveloppe).</li>
              <li>Les spectacles sont gratuits pour les 10 ans et moins, nul besoin de billet.</li>
              <li>Les Passeports seront envoyés au courant du mois de juin.</li>
              <li>Le Bus Festif! Radio-Canada est accessible aux personnes de 18 ans et plus.</li>
              <li>Le Bus Festif! et Les expériences d’écoute sont des exclusions au Passeport La Totale. Un billet sera nécessaire pour participer à ces activités.</li>
              <li>Les enfants sont les bienvenus dans Les expériences d’écoute, mais ils et elles doivent avoir un billet.</li>
              <li>Pour chaque billet vendu à la Scène Loto-Québec et à la Scène Télé-Québec, 1$ sera directement ajouté à notre fonds développement durable et sera entre autres utilisé pour compenser les gaz à effet de serre produits pendant le festival.</li>
            </ul>
            <h2 className="!text-left mb-2 mt-6">Prévente locale</h2>
            <ul>
              <li>10 avril de 7h à 18h au Pavillon Jacques St-Gelais du Musée d’art contemporain de Baie-Saint-Paul</li>
              <li>Maximum d’achat de 2 Passeports La Totale par personne</li>
              <li>Maximum d’achat de 4 Passeports Place Desjardins</li>
              <li>Maximum d’achat de 4 billets par show par personne</li>
              <li>Les spectacles sont gratuits pour les enfants de 10 ans et moins, nul besoin de billet.</li>
            </ul>
            <h2 className="!text-left mb-2 mt-6">Ouverture de la billetterie en ligne</h2>
            <ul>
              <li>11 avril à midi</li>
              <li>Maximum d’achat de 2 Passeports La Totale par personne</li>
              <li>Maximum d’achat de 4 Passeports Place Desjardins</li>
              <li>Maximum d’achat de 4 billets par show par personne</li>
              <li>Maximum d’achat de 2 camping par personne</li>
              <li>Les spectacles sont gratuits pour les enfants de 10 ans et moins, nul besoin de billet.</li>
            </ul>

            <h2 className="mb-2 mt-6 !text-left">Un Festif! ensemble</h2>
            <p className="text-white">Chaque année, Le Festif! contribue au bien-être collectif et à la fabrication du lien social de sa communauté en présentant diverses activités culturelles axées sur la musique. Conscient que certaines personnes peuvent rencontrer des défis, Le Festif! souhaite favoriser l’accès de toutes et tous aux spectacles. C'est pourquoi le programme Le Festif! Ensemble permettra à plusieurs organismes communautaires de la région de recevoir gratuitement des billets destinés à leur clientèle. Ce projet est possible, entre autres, grâce à la contribution de <a href="https://www.stratejia.ca/">Stratéjia.</a></p>

            <h2 className="mb-2 mt-6 !text-left">Politique de dons et commandites</h2>
            <p className="text-white">Le Festif! a adopté cette politique de dons et commandites en lien avec ses valeurs et ses priorités. Les secteurs culturel, jeunesse et éducation sont admissibles. Les dons et commandites se limitent à des billets ou passeports pour les différents projets du festival, du prêt de matériel, des commandites en biens et/ou services. Aucun don ou commandite ne se fera sous forme monétaire. La politique du Festif! limite ses dons et commandites au territoire de Charlevoix.</p>

          </div>

          {/* BUTTONS */}
          <div className='mb-9'>
            <div className="px-4 my-16">
              <p className="my-12"><BigButton href="https://prismic-io.s3.amazonaws.com/le-festif-ca/96905991-cf12-4e07-a089-0ce1b4b863c5_Politique+de+remboursement+%2B+politique+de+prise+de+photos.pdf"
                                              text="POLITIQUE DE REMBOURSEMENT + PRISE DE PHOTOS & VIDÉOS"
                                              target='_blank' /></p>
              <p><BigButton href="https://forms.gle/jewnQgDyrdeRhApeA"
                            text="PROMESSE DES FESTIVALIER.ÈRE.S"
                            target='_blank' /></p>
            </div>
          </div>

      </div>
    </Wolflayout>
  )
}

export default Billetterie
